body {
  margin: 0;
  text-align: center;
  font-size: 1em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

h1 {
  font-size: 1.2em;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
  color: #333;
}

.game {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.board {
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden; */
}

.row {
  text-align: center;
  display: flex;
  justify-content: center;
}

.row>div {
  display: block;
  width: 60px;
  height: 60px;
  border: 1px solid #bbb;
  margin: 2px;
  text-align: center;
  line-height: 60px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.5em;
  color: #000;
}

.row>div.green {
  --background: #6aaa64;
  --border-color: #6aaa64;
  animation: flip 0.5s ease forwards;
}

.row>div.yellow {
  --background: #c9b458;
  --border-color: #c9b458;
  animation: flip 0.5s ease forwards;
}

.row>div.grey {
  --background: #787c7e;
  --border-color: #787c7e;
  animation: flip 0.5s ease forwards;
}

.row>div:nth-child(2) {
  animation-delay: 0.2s;
}

.row>div:nth-child(3) {
  animation-delay: 0.4s;
}

.row>div:nth-child(4) {
  animation-delay: 0.6s;
}

.row>div:nth-child(5) {
  animation-delay: 0.8s;
}

.row.current>div.filled {
  animation: bounce 0.2s ease-in-out forwards;
}


.modal {
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.modal div {
  max-width: 480px;
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  margin: 10% auto;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.modal .solution {
  color: #ff004c;
  font-weight: bold;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@keyframes flip {
  0% {
    transform: rotateX(0);
    background: #fff;
    border-color: #333;
  }

  45% {
    transform: rotateX(90deg);
    background: #fff;
    border-color: #333;
  }

  55% {
    transform: rotateX(90deg);
    background: var(--background);
    border-color: var(--border-color);
  }

  100% {
    transform: rotateX(0);
    background: var(--background);
    border-color: var(--border-color);
    color: #eee
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
    border-color: #ddd;
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
    border-color: #333;
  }
}

.keyboard {
  text-align: center;
  font-size: 14px;
  font-family: sans-serif;
  margin-bottom: 10px;
}

.keyboard__row {

  display: inline-flex;
  height: 3em;
  margin: 0.2em;
}

.keyboard__row>* {
  position: relative;
  background: #d3d6da;
  text-align: center;
  color: #333;
  float: left;
  border-radius: 0.3em;
  margin: 0.2em;
  padding: 0.2em;
  width: 3em;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  border: 0;
  text-transform: uppercase;
}

.key--w3 {
  width: 4.6em;
}

.key--letter {
  line-height: 2.8em;
}

.keyboard>.keyboard__row {
  text-align: center;
}

.keyboard__row>button.green {
  background: #6aaa64;
  color: #fff;
  transition: all 0.3s ease-in;
}

.keyboard__row>button.yellow {
  background: #c9b458;
  color: #fff;
  transition: all 0.3s ease-in;
}

.keyboard__row>button.grey {
  background: #787c7e;
  color: #fff;
  transition: all 0.3s ease-in;
}